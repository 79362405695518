@import "normalize.css/normalize.css";
@import "variables";
// @import "flexmonster/flexmonster.min.css";
// @import "flexmonstertheme";
/* You can add global styles to this file, and also import other style files */

html,
body {
    height: 100%;
    box-sizing: border-box;
}

*,
*:before,
*:after {
    box-sizing: inherit;
}

body {
    margin: 0;
    font-family: Roboto, "Helvetica Neue", sans-serif;
}

h1,
h2,
h3,
h4,
h5 {
    color: $primary;
    margin: 0px;
}

h1 {
    font-size: 24px;
    margin-bottom: 20px;
}

h2 {
    font-size: 20px;
}

h3 {
    font-size: 18px;
}

h4 {
    font-size: 16px;
}

h5 {
    font-size: 14px;
}

.datepicker-input {
    cursor: pointer;
}

.mat-mdc-table {
    width: 100%;

    th {
        color: $accent;
    }
}

.mat-mdc-paginator {
    color: $accent;

    .mat-mdc-paginator-icon {
        color: $primary;
    }
}

/* TODO(mdc-migration): The following rule targets internal classes of card that may no longer apply for the MDC version.*/
.mat-card-header-text {
    margin: 0 !important;
}

/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
.mat-focused .mat-form-field-label {
    /*change color of label*/
    color: $accent !important;
}

/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
.mat-form-field-underline {
    /*change color of underline*/
    background-color: $accent !important;
}

/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
.mat-form-field-ripple {
    /*change color of underline when focused*/
    background-color: $accent !important;
}

.mat-form-field-disabled {
    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
    .mat-form-field-underline {
        background-color: $primary-light !important;
    }
}

// .mat-progress-spinner circle,
// .mat-mdc-progress-spinner circle {

.mat-form-field-appearance-fill .mat-form-field-flex {
    background-color: transparent!important;
}
.mat-form-field-appearance-fill .mat-form-field-underline::before{
    background-color: $accent !important;
}

.mat-progress-spinner circle,
.mat-spinner circle {
    stroke: $accent;
}

.mat-mdc-raised-button {
    background-color: $accent;
    color: $contrast-color;
}

.mat-mdc-icon-button {
    color: $accent;
}

.smaller-raised-button {
    line-height: 32px !important;
    padding: 0 8px !important;
}

.mat-mdc-tooltip {
    font-size: 0.9em !important;
}

.mat-mdc-menu-panel {
    min-height: 50px !important;
}

.mat-datepicker-toggle-default-icon {
    height: 1em !important;
}

.mat-mdc-optgroup.sticky {
    .mat-mdc-optgroup-label {
        position: sticky;
        top: 0;
        z-index: 1;
        background-color: $contrast-color;
        padding-inline: 0;
    }
}

// used to disable mat-step header with aria-labelledby="disabled"
.mat-step-header[aria-labelledby="disabled"] {
    pointer-events: none !important;
    opacity: 0.6;
}

@supports (scrollbar-color: red blue) {
    .mat-drawer-inner-container {
        scrollbar-color: $accent $primary-dark;
        scrollbar-width: thin;

        mat-nav-list {
            scrollbar-color: $accent $primary-dark;
            scrollbar-width: thin;
        }
    }
}

.mat-drawer-inner-container {
    &::-webkit-scrollbar {
        width: 0.5em;
    }

    // &::-webkit-scrollbar-track {
    //     background-color: $contrast-color;
    // }

    &::-webkit-scrollbar-thumb {
        background: $accent;
    }

    mat-nav-list {
        &::-webkit-scrollbar {
            width: 0.5em;
        }

        &::-webkit-scrollbar-thumb {
            background: $accent;
        }
    }
}

.mat-mdc-checkbox.checkbox-select-all {
    /* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
    /* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
    .mat-checkbox-layout {
        width: 100% !important;
        /* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
        .mat-checkbox-inner-container {
            margin: auto 8px auto 0;
        }
    }
}

.mat-primary .mat-pseudo-checkbox-checked {
    background-color: $accent;

    &::after {
        color: $contrast-color!important;
    }
}

fa-icon {
    display: inline-block;
    height: 24px;
    width: 24px;

    fill: currentColor;
    background-repeat: no-repeat;
    font-size: 16px;
    line-height: 24px;
}

.select-list-fit-content {
    max-width: fit-content !important;
}

.estimator-creation>div {
    overflow: hidden;
}

.mat-pseudo-checkbox-checked {
    background: $accent!important;
}

// #region Components
app-anti-strategies {
    /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
    // .mat-tab-labels :last-child.mat-tab-label {
    //     min-width: 0;
    //     padding: 0 12px;
    //     color: unset;
    //     opacity: unset;
    // }

    .download-excel {
        margin-left: 16px;
    }

    .mat-mdc-tab-disabled {
        opacity: 1!important;
    
        .mdc-tab__ripple {
            display: none!important;
        }
    }
}

app-scale-amount,
app-estimator {
    /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
    .mat-tab-labels :last-child.mat-tab-label {
        min-width: 0;
        padding: 0;
        color: unset;
        opacity: unset;
    }
}

app-change-password {
    /* TODO(mdc-migration): The following rule targets internal classes of card that may no longer apply for the MDC version.*/
    /* TODO(mdc-migration): The following rule targets internal classes of card that may no longer apply for the MDC version.*/
    .mat-card-header-text {
        margin: 0 auto!important;
    
        .mat-mdc-card-title {
            display: flex;
        
            h2 {
                margin: 0 auto 16px auto;
            }
        }
    }
}

app-fte-encoding {
    .fte-too-big {
        /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
        .mat-form-field-underline {
            background-color: red !important;
        }
        color: red !important;
    }
}
// #endregion Components




/*               ANGULAR MATERIAL LEGACY styling in MATERIAL MDC              */

/* -------------------------------------------------------------------------- */
/*                             *     GLOBALS     *                            */
/* -------------------------------------------------------------------------- */

/* ----------------------- letter spacing mdc material ---------------------- */
  * {
    letter-spacing: normal !important;
  }

/* -------------------------- Form fields mdc material ------------------------- */

// initial background color
.mdc-text-field--filled:not(.mdc-text-field--disabled),
.mat-mdc-form-field-focus-overlay,
.mdc-text-field--disabled.mdc-text-field--filled {
    background-color: transparent!important;
}
.mat-mdc-form-field-subscript-wrapper, .mat-mdc-form-field-bottom-align::before{
    line-height: 8px!important
}
// mat form field outline and label color 
.mdc-text-field--filled:not(.mdc-text-field--disabled) .mdc-line-ripple::before, 
.mdc-text-field--filled:not(.mdc-text-field--disabled):hover .mdc-line-ripple::before{
    border-bottom-color: $accent!important
}
.mat-mdc-form-field:not(.mat-form-field-no-animations) .mdc-line-ripple::after,
.mdc-text-field--filled .mdc-line-ripple::after,
.mdc-text-field--filled .mdc-line-ripple.mdc-line-ripple--active::after
 {
    border-bottom-color: $accent!important;
}
.mdc-text-field--focused:not(.mdc-text-field--disabled) .mdc-floating-label{
    color: $accent!important;
}
//mat-form-field height and font size
.mat-mdc-form-field-infix {
    min-height: 40px!important;
}
.mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined) .mat-mdc-form-field-infix{
    padding-bottom: 0!important;
    padding-top: 20px!important;
}
.mdc-floating-label--float-above{
    padding-bottom: 4px!important;
}
.mat-mdc-form-field, .mat-form-field-disabled {
    --mdc-typography-body1-font-size: 14px!important;
    --mdc-filled-text-field-label-text-size: 14px!important;
    --mat-select-trigger-text-size: 14px!important;
    .mat-mdc-form-field-input-control.mat-mdc-form-field-input-control{
        font-size: 14px!important;
    }
}
.mdc-text-field{
    padding: 0px!important;
}
// mat form field icons
.mat-mdc-form-field-icon-prefix>.mat-icon, .mat-mdc-form-field-icon-suffix>.mat-icon{
    padding: 0px!important;
    padding-top: 4px!important;
    padding-right: 4px!important
}
// .material-icons{
//     font-size: 21px;
// }
.mat-mdc-form-field-hint-wrapper{
    padding: 0px!important;
    a{
        font-size: 11px!important
    }

    .mat-mdc-form-field-hint {
        font-size: 10.5px;
        line-height: 12px;
    }
}
.mat-mdc-form-field-error-wrapper{
    padding: 0px!important;
    font-size: 11px!important;

    .mat-mdc-form-field-error{
        font-size: 10.5px!important
    }
}
// .mat-mdc-form-field{
//     margin-bottom: 0px!important;
// }

// remove shadow effect on buttons when hovering
// .mat-mdc-raised-button:hover{
//   box-shadow: none !important;
// }
.mat-form-field-appearance-fill .mat-mdc-select-arrow-wrapper {
    transform: translateY(-6px)!important;
}
.mat-mdc-select-arrow-wrapper{
    margin-bottom: -4px!important;
}
.mat-mdc-select-trigger, .mat-mdc-select-trigger{
    align-items: flex-end!important
}
.mat-mdc-select-panel{
    padding: 0px!important;
}

//   mat paginator lines per page 
.mat-mdc-paginator-page-size-select {
    width: 64px!important;
}
mat-paginator {
    .mat-mdc-select-trigger{
        border-bottom: 1px solid $accent!important;
        padding-bottom: 2px!important;
    }
    .mdc-notched-outline > * {
        border: none !important;
    }
    .mat-mdc-paginator-range-label, .mat-mdc-paginator-page-size-label {
        color: $accent!important;
    }
    .mat-mdc-tooltip:not(.mat-mdc-tooltip-disabled) .mat-mdc-paginator-icon{
        color:$primary!important;
    }
    .mat-mdc-paginator-icon {
        fill: $primary !important;
    }   
    button.mat-mdc-tooltip-disabled .mat-mdc-paginator-icon {
        opacity: 0.3!important;
    }
}

/* ------------------------------ mat mdc cards ----------------------------- */

.mat-mdc-card{
    padding: 8px!important
}
.mat-mdc-card-content:last-child {
    padding-bottom: 8px!important;
}
.mat-mdc-card-content:first-child {
    padding-top: 0px!important;
}
.mat-mdc-card-content {
    padding-bottom: 16px!important;
}

//other cards
app-story-line, app-report-layouts, 
app-users, app-organigram-display, 
app-upload, app-conjonctural-parameters, 
app-functional-article, app-scale-amount,
app-replacements, app-substitution-scale-payment,
app-tags-management, app-parameter, 
app-extracted-file-status, app-file-template-client-configuration,
app-query-extraction, app-universe, 
app-text, app-executions-list, app-error-logs{
    .mat-mdc-card{
        padding-top: 16px!important;
    }
    .mat-mdc-card-content {
        padding: 0 8px!important;
    }
}

/* ------------------------------- checkboxes ------------------------------- */

.mat-checkbox .mat-checkbox-inner-container {
    width: 24px !important;
    height: 24px !important;
}
.mat-checkbox .mat-checkbox-frame {
    width: 24px !important;
    height: 24px !important;
}
.mat-checkbox .mat-ripple {
    width: 24px !important;
    height: 24px !important;
    border-radius: 50% !important;
}
.mat-mdc-checkbox-touch-target{
    height: 40px!important;
    width: 40px!important;
}
.mat-checkbox {
    margin: 0 !important; 
    padding: 0 !important;
}
.mat-mdc-checkbox {
    --mdc-checkbox-state-layer-size: 16px;
    .mat-mdc-checkbox-ripple,
    .mdc-checkbox__ripple {
      top: -8px!important;
      left: -8px!important;
      right: -8px!important;
      bottom: -8px!important;
    }
    label {
      padding-left: 6px !important;
    }
}
/* ---------------------------- small checkboxes ---------------------------- */

app-dashboard .edit-dashboard, app-story-edit,
app-anti-strategies .reconciliation-filters, app-estimators-list,
app-estimator-creation .estimator-form, app-detail-events .scenario-select,
app-report-filter, app-layout-details form .layout-form,
app-users, app-profile-roles, app-profile-reports,  app-budget-filters, 
app-functional-article, app-file-template-client-configuration, app-nomination,
app-wiki .input-form mat-expansion-panel{
    .mdc-checkbox__background {
        width: 16px !important;
        height: 16px !important;
        top: 50% !important; 
        left: 50% !important; 
        transform: translate(-50%, -50%) !important; 
    }
}

/* --------------------------- smaller checkboxes --------------------------- */

app-dependencies-list .select-all, 
app-structure-edit form{
    .mdc-checkbox__background {
        width: 14px !important;
        height: 14px !important;
        top: 50% !important; 
        left: 50% !important; 
        transform: translate(-50%, -50%) !important; 
    }
}

/* --------------------- buttons and checkboxes ripples --------------------- */

.mat-mdc-icon-button .mat-mdc-button-persistent-ripple
// , .mat-mdc-checkbox .mdc-checkbox__ripple
{
    display:none;
}
.mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__native-control:not([disabled]):focus~.mdc-checkbox__ripple {
    opacity: 0!important;
}

/* ----------------------------- mat tab labels ----------------------------- */

app-anti-strategies, app-scenarios, app-component-details,
app-report-custom, app-functional-article, app-scale-amount,
app-error-logs, app-editor-container{
    .mat-mdc-tab-list{
        flex-grow: initial!important;
    }
}

/* --------------------------------- tables --------------------------------- */

// table header 
.mat-mdc-tab-header-pagination-chevron:not([disabled]) {
    border-color: $accent; 
}
mat-tab-group mat-tab-header{
    border-bottom: 1px solid rgba(0, 0, 0, 0.12)!important;
}
// line-height of table cells
table {
    th, td{
        line-height: 16px!important;
    }
}
// rows height
.mat-mdc-table .mdc-data-table__row.row-pattern, 
.mat-mdc-table .mdc-data-table__row.row-estimator{
        height: 48px!important;
        max-height: 48px!important;
    }
app-payment-section, app-fte-encoding, app-report-advanced, app-profile-roles,
app-profile-reports, app-detail-prestation, app-article-detail, app-article-mapping,
app-upload-budget, app-extracted-file-status, app-http-requests, app-events-table-server, 
app-events-table-reconciliation, app-scenario-summary, app-salary-index, app-upload-forecast-payments, 
app-estimators-list, app-contract-state, app-users, app-scale-amount-evolution-groups, app-replacements,
app-substitution-scale-payment, app-file-template-client-configuration {
    .mat-mdc-table {
        .mdc-data-table__row {
            height: 48px!important;
            max-height: 48px!important;
    
            &:not(.detail-event, .detail-param, .detail-origin, .detail-estimator, .detail-ref-situation, 
            .detail-row, .detail-scale-amount-evolution, .detail-substitution-scale-payment, .example-detail-row) {
                    height: 48px!important;
                    max-height: 48px!important;
            }
    
            &.detail-event, 
            &.detail-param,
            &.detail-origin, 
            &.detail-estimator,
            &.detail-row, 
            &.detail-scale-amount-evolution,
            &.detail-substitution-scale-payment, 
            &.example-detail-row {
                height: 0px!important;
            }
        }
    }
}

/* ----------------------------- month selector ----------------------------- */

app-month-selector, app-fte-encoding-month-selector, 
app-structure-select .datePicker, app-requests-filter .time-stamp,
 app-logs-filter .time-stamp, app-hangfire-filter .time-stamp{
    /* Réduire la taille du bouton de l'icône */
    .mat-mdc-icon-button.mat-mdc-button-base {
        width: 30px!important;
        height: 30px!important;
        min-width: 30px!important;
        min-height: 30px!important;
    /* Optionnel : ajuster le padding/marge si nécessaire */
        padding: 0px!important;
    }
    /* Ajuster la taille de l'icône */
    .mat-mdc-icon-button.mat-mdc-button-base svg {
        width: 14px!important;
        height: 14px!important;
    }
    /* Réduire la taille du ripple */
    .mat-mdc-button-ripple {
        width: 30px!important;
        height: 30px!important;
    }
    /* Ajuster la zone tactile du bouton pour correspondre à la nouvelle taille */
    .mat-mdc-button-touch-target {
        width: 30px!important;
        height: 30px!important;
    }
    .mat-mdc-form-field-icon-suffix{
        margin-bottom: -8px
    }
} 

/* ----------------------------- select check all ----------------------------- */

app-select-check-all {
    .mdc-checkbox {
        margin-right: 10px!important;
    }
}

/* ----------------------------- slide toggle buttons ----------------------------- */

mat-slide-toggle .mdc-switch__icon {
    display: none;
}
mat-slide-toggle .mdc-switch.mdc-switch--selected .mdc-switch__handle::after {
    background-color: $accent !important;
  }
  
mat-slide-toggle .mdc-switch .mdc-switch__handle::after {
    background-color: white !important;
}
mat-slide-toggle .mdc-switch:enabled .mdc-switch__track::before{
    background-color: rgba(0, 0, 0, 0.38)
}

/* ----------------------------- Mat Icon Buttons Sizes ------------------------------ */

.small-icon-button {
    width: 32px !important;
    height: 32px !important;
    padding: 0px !important;
    display: inline-flex !important;
    align-items: center;
    justify-content: center;
 
    & > *[role=img] {
       width: 24px;
       height: 24px;
       font-size: 24px;
 
       svg {
          width: 24px;
          height: 24px;
       }
    }

    .mat-mdc-button-touch-target {
        width: 32px !important;
        height: 32px !important;
    }
 }

.smaller-icon-button {
    width: 24px !important;
    height: 24px !important;
    padding: 0px !important;
    display: inline-flex !important;
    align-items: center;
    justify-content: center;
 
    & > *[role=img] {
       width: 24px;
       height: 24px;
       font-size: 24px;
 
       svg {
          width: 24px;
          height: 24px;
       }
    }

    .mat-mdc-button-touch-target {
        width: 24px !important;
        height: 24px !important;
    }
 }

 /* -------------- Overlay panels select fields - most current -------------- */

.cdk-overlay-pane:has(
    .profile, .dashboard-select-report-option, .header-select-report-option, .fte-encoding-select-encoding-option,
    .structure-select-select-structure, .agent-detail-events-select-scenario, .report-filter-select-value,
    .advanced-select-form-select-universe, .advanced-select-form-select-report, .pivot-toolbar-select-layout,
    .user-edit-select-profile, .upload-form-select-template, .upload-excel-events-select-usertag,
    .budget-filters-select-fileStatus, .event-edit-multiple-parameter, .event-toolbar-filter, .strategy-select,
    .pension-by-age-status-group, .scenario-summary-select, .user-tag-option, .strategy-motif, .forecast-payment-option
    .event-articles-functional-option, .event-detail-option, .universe-select-overlay, .universe-edit-select-function-query,
    .universe-edit-select-query, .universe-edit-select-client, .dimension-edit-select-query,
    .text-create-download-type-select, .text-create-select-language, .text-edit-select-language,
    .requests-filter-select-request-method, .logs-filter-select-level, .hangfire-filter-select-job-type,
    .hangfire-filter-succeedeed-status){
    width: auto!important;
    min-width: 180px!important;
    mat-option{
        font-size: 14px!important;
    }
}
.cdk-overlay-pane:has(
    .component-details-select-user-tag, .hiring-plan-select-scenario, .pension-select-scenario,
    .layout-details-select-detail-type, .details-kpi-select-chart-type, .details-kpi-select-metadata, 
    .details-kpi-select-filter-value, .contract-filter-select-scenario) {
    width: fit-content!important;
    min-width: 180px!important;
      mat-option{
        font-size: 14px!important;
    }
}
.cdk-overlay-pane:has(
    .autocomplete-select-operator, .events-toolbar-select-operator, .month-selector-select-operator,
    .autocomplete-multiple-select-operator, .scale-amount-filters-select-operator, 
    .scale-amount-evolution-filters-select-operator) {
        width: auto!important;
        min-width: 50px!important;
    mat-option{
        font-size: 14px!important;
    }
}
.cdk-overlay-pane:has(.autocomplete-select-filtered-item) {
    width: fit-content!important;
    min-width: 50px!important;
}

/* ---------------- Overlay panels select fields - exceptions --------------- */

.cdk-overlay-pane:has(.language-selector-select-language) {
    width: auto!important;
    min-width: 110px!important;
}
.cdk-overlay-pane:has(
    .profile){
    .mat-mdc-menu-item-text{
        font-size: 14px!important;
    }
    .mat-mdc-menu-item{
        color: rgba(0, 0, 0, 0.87);
    }
    .mat-icon-no-color{
        color: #707070;
    }
}
.cdk-overlay-pane:has(.event-type-select-select-event) {
    mat-option{
        font-size: 14px!important;
    }
}
.cdk-overlay-pane:has(.autocomplete-multiple-select-filtered-item) {
    width: fit-content!important;
    min-width: 280px!important;
    mat-option{
        font-size: 14px!important; 
    }
}
.cdk-overlay-pane:has(.organigram-select-filter) {
    width: auto!important;
    min-width: 180px!important;
}
.cdk-overlay-pane:has(.pivot-toolbar-select-layout) {
    left: auto !important;
    right: 44px!important;
    .mdc-list-item__primary-text{
        white-space: nowrap!important;
      }
}
.cdk-overlay-pane:has(
    .agent-selector-select-agent, .event-type-select-select-event) {
        width: 250px!important;
        min-width: 180px!important;
}
.cdk-overlay-pane:has(.upload-excel-events-select-usertag) {
    .mat-mdc-option .mat-icon, .mat-mdc-option .mat-pseudo-checkbox-full{
        margin-right: 8px!important;
    }
}
.cdk-overlay-pane:has(
    .tag-edit-select-tagtype, .substitution-scale-payment-edit-select-strategy)
   {
    width: 200px!important;
    min-width: 180px!important;
    .mat-mdc-select-panel{
        width: 100%!important;
    }
    mat-option{
        font-size: 14px!important;
    }
}
.cdk-overlay-pane:has(
    .query-extraction-select-query, .query-extraction-select-value){
    width: auto!important;
    min-width: 678.5px!important;
    mat-option{
        font-size: 14px!important;
    }
}
.cdk-overlay-pane:has(.query-extraction-select-value){
    top: 290px!important;
}

/* -------------------------------------------------------------------------- */
/*                           *     SPECIFICS      *                           */
/* -------------------------------------------------------------------------- */

/* ------------------------- LOGIN module specifics ------------------------- */

app-login{
    .mat-icon{
        margin-right: 0px!important;
    }
    .material-icons{
        font-size: 21px;
    }
    .hidePwButton{
        color: $accent!important;
        .material-icons{
            font-size: 14px;
        }
        .mat-mdc-button-touch-target {
            width: 32px !important;
            height: 32px !important;
            pointer-events: auto !important;
          }
      }
      .mat-mdc-icon-button.mat-mdc-button-base{
        width: 35px;
        height: 35px;
        padding: 10px 0px 0px 0px;
      }
      .mat-mdc-card-header{
        padding-top: 16px!important;
      }
      .mat-mdc-card-content:last-child{
        padding-bottom: 16px!important;
      }
      .mat-mdc-form-field-bottom-align::before{
        height: 14px!important
      }

} 

/* ------------------------ MAIN NAV module specifics ----------------------- */

app-main-nav {
    .main-nav-panel .mat-expansion-panel-body {
        padding: 0px 0 8px !important;
    }
    // normal links 
    .mat-mdc-list-item-unscoped-content.mdc-list-item__primary-text{
        display: flex!important;
        align-items: center!important;
        color: $primary-light!important;
        ;
        .material-icons{
            font-size: 24px;
        }
        .link-label{
            white-space: normal; 
            line-height: 1.125em;
        }
    }
    .mat-expansion-panel-header{
        font-weight: 400!important;
    }
    // active links
    .router-link-active .mat-mdc-list-item-unscoped-content.mdc-list-item__primary-text{
        color: $accent!important
    }
    .mdc-list-item:focus::before{
        background: rgba(0, 0, 0, 0.3)!important;
    }
    // version credits 
      .version {
        padding: 0px!important;
        .mat-mdc-list-item-unscoped-content.mdc-list-item__primary-text {
            font-size: 1em !important;
            color: $contrast-color !important;
            display: flex!important;
            img{
                margin-top: 4px;
            }
          }
      }
}

/* ---------------------- MAIN HEADER module specifics ---------------------- */

app-main-header {
    .actions mat-icon{
        height: 24px!important;
        width: 24px!important
    }
    .actions .mat-mdc-button>.mat-icon{
        font-size: 1.5rem;
        margin-right: 1px!important;
    }
    .actions .mat-mdc-button>.mat-icon:first-of-type{
        margin-right: 5px!important
    }
    .actions .mat-mdc-button.userButton>.mat-icon:first-of-type{
        margin-right: 0px!important
    }
    .actions .mat-mdc-button{
        margin-right: 8px;
    }
    .mat-mdc-menu-content .mat-mdc-menu-item mat-icon{
        margin-right: 12px!important;
    }
    .mdc-button__label{
        display: flex!important;
        align-items: center!important;
      }
      .mdc-button__label .user{
        margin-left: 3px!important;
    }
    .mat-mdc-menu-content .mat-mdc-menu-item{
        color: #707070!important;
        .mdc-list-item__primary-text {
          font-size: 14px!important;
        }
        .mat-icon-no-color{
          color: #707070!important;
        }
      }
}

app-language-selector .language-form mat-form-field mat-label {
    display:none;
}

/* ----------------- DASHBOARD + HEADER modules specifics ----------------- */

app-dashboard {
    .edit-dashboard{
        height: 24px!important;
    }
    .mat-mdc-checkbox {
        .mdc-form-field label {
          font-size: 16px !important; 
        }
    }
    .mat-mdc-card {
        padding: 0px !important;
    }
    .mat-mdc-card-header{
        padding: 8px 16px 8px!important;
    }
} 

/* --------------------------- STORYLINE specifics -------------------------- */

app-anti-strategies{
    .mat-mdc-tab:not(.anti-strategies-buttons){
        min-width: 160px!important;
    }
    .mat-mdc-tab:not(.mdc-tab--active){
        opacity: 0.7;
    }
    .mat-mdc-tab.anti-strategies-buttons{
        padding: 0px!important;
        min-width: 0px!important;
        width: auto;
    }
    .mat-mdc-tab-disabled.anti-strategies-buttons{
        opacity: 1;
    }
}

app-scenarios{
    .mat-mdc-tab{
        min-width: 160px!important;
    }
}

app-events-table-reconciliation table .mat-column-actions{
            // buttons 
            .mat-mdc-icon-button.mat-mdc-button-base {
                width: 40px!important;
                height: 40px!important;
                min-width: 40px!important;
                min-height: 40px!important;
            /* Optionnel : ajuster le padding/marge si nécessaire */
                padding: 0px!important;
            }
            /* Ajuster la taille de l'icône */
            .mat-mdc-icon-button.mat-mdc-button-base svg {
                width: 14px!important;
                height: 14px!important;
            }
            /* Réduire la taille du ripple */
            .mat-mdc-button-ripple {
                width: 40px!important;
                height: 40px!important;
            }
            /* Ajuster la zone tactile du bouton pour correspondre à la nouvelle taille */
            .mat-mdc-button-touch-target {
                width: 40px!important;
                height: 40px!important;
            }
            .mat-mdc-form-field-icon-suffix{
                margin-bottom: -8px
            }
}


/* ------------------ ENCODAGE DIRECTEURS module specifics ------------------ */

app-fte-encoding {
    .submit-header .mdc-text-field--disabled .mdc-line-ripple::before {
        border-bottom: dotted 1px rgba(0, 0, 0, 0.42)!important;
        }
    .mat-mdc-card{
            padding:16px!important
        }
    .add-encoding{
            margin-bottom: 50px
        }
        .mdc-notched-outline__trailing,
        .mdc-notched-outline__notch,
        .mdc-notched-outline__leading {
            border: none !important;
        } 
}

app-add-encoding{

    .mdc-button.mat-unthemed{
        background-color: $accent!important;
        color: white;
    }
    .actions{
        .mdc-button.mat-unthemed{
            background-color: $accent!important;
            color: white;
        }
        span{
            font-size: 14px
        }
    }     
}

/* ------------------------ MAPPING module specifics ------------------------ */

app-dependencies-list{
    .dependencies-list {
        .fit .empty .mdc-list-item__content span{
            white-space: normal
        }
        .fit .mat-mdc-list-item-unscoped-content{
            display: flex!important;
            align-items: center!important;
            justify-content: space-between!important
        }
        .buttonsMove-group {
            float: right!important
          }
    }
}

app-payment-section, 
app-professional-status {
    .mat-mdc-card{
        padding: 16px!important;
    }
    .mat-mdc-card-content{
        padding: 0px!important;
    }
}

app-tree-mapping{
    header{
        margin: 0 0 8px 0!important;
    }
}

/* ----------------------- ESTIMATEURS module specifics ---------------------- */

app-estimator{
    .mat-mdc-card{
        padding: 16px!important;
    }
    .mat-mdc-card-content{
        padding: 0px!important;
    }
    .mdc-tab__text-label{
        line-height: 16px!important;
    }
    .mat-mdc-tab-disabled {
        opacity: 1!important;
    
        .mdc-tab__ripple {
            display: none!important;
        }
    }
}

app-estimators-list{
    .mat-sort-header-content{
        line-height: 16px!important;
    }
    .mat-mdc-form-field-icon-suffix{
        margin-bottom: -8px
    }
}

/* -------------------------- FICHE AGENT specifics ------------------------- */

app-contract-state{
    .mat-mdc-table {
        th{
            .mat-sort-header-content {
                text-align: left!important;
            }
        }
    }
}

app-prestation-filter{
    /* Réduire la taille du bouton de l'icône */
    .mat-mdc-icon-button.mat-mdc-button-base {
        width: 30px!important;
        height: 30px!important;
        min-width: 30px!important;
        min-height: 30px!important;
    /* Optionnel : ajuster le padding/marge si nécessaire */
        padding: 0px!important;  
    }
    /* Ajuster la taille de l'icône */
    .mat-mdc-icon-button.mat-mdc-button-base svg {
        width: 14px!important;
        height: 14px!important;
    }
    /* Réduire la taille du ripple */
    .mat-mdc-button-ripple {
        width: 30px!important;
        height: 30px!important;
    }
    /* Ajuster la zone tactile du bouton pour correspondre à la nouvelle taille */
    .mat-mdc-button-touch-target {
        width: 30px!important;
        height: 30px!important;
    }
    .mat-mdc-form-field-icon-suffix{
        margin-bottom: -8px
    }

    .mat-mdc-form-field-infix{
        width: 160px!important;
    }
    .submitSection .resetFilter{
        margin-left: 4px;
        margin-top: 4px
    }
}

app-detail-prestation{
    .mat-mdc-table th .mat-sort-header-content{
        text-align: left!important;
    }
}

/* ----------------------- OUTIL DE RAPPORT specifics ----------------------- */

app-report-basic{
    .mat-mdc-card-content{
        padding: 0 8px!important;
    }
    .mat-mdc-card{
        padding: 16px 8px !important
    }
}

app-filter-list{
    .mat-mdc-list-item-unscoped-content.mdc-list-item__primary-text{
        display: flex!important;
        align-items: center!important; 
        justify-content: space-between!important;
      }
      span{
        white-space: normal;
      }
}

app-toolbar{
    .pivot-toolbar{
        .layouts form {
            .mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined) .mat-mdc-form-field-infix{
                padding-bottom: 0!important;
                padding-top: 14px!important;
            }
        }
        .actions button{
            mat-icon{
                height: 30px;
            }
            .mat-mdc-icon-button.mat-mdc-button-base {
                padding: 10px!important;
            }
        }
    }
}

app-report-advanced{
    .report-select{
        padding: 8px 0 0 0;
    }
    .mat-mdc-card-content {
        padding: 0 8px!important;
    }
}

app-advanced-select-form .report-form{
    .small-icon-button{
        margin-top: 16px!important;
        margin-right: 5px!important;;
    }
    .mat-mdc-form-field .smaller-icon-button{
        margin-top: 16px!important;
        .mat-icon {
            width: 14px!important;
            height: 14px!important;
            font-size: 14px!important;
        }
    }
}

app-report-grid{
    .mat-mdc-card-content:first-child {
        padding: 8px 8px!important;
    }
}

app-reports-select {
    .mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined) .mat-mdc-form-field-infix {
        padding-bottom: 0 !important;
        padding-top: 16px !important;
    }
}

/* --------------------- GESTION UTILISATEURS specifics --------------------- */
//
/* ------------------------ GESTION PROFILS specifics ----------------------- */
//
/* ------------------------- ORGANIGRAMME specifics ------------------------- */

app-organigram-display{
    form .mat-mdc-form-field .mat-mdc-floating-label:not(.mdc-floating-label--float-above) {
        white-space: nowrap!important;
        overflow: hidden!important;
        text-overflow: ellipsis!important;
        max-width: 100%; 
    }
}

/* ---------------------- IMPORT DE FICHIERS specifics ---------------------- */
//
/* ---------------------- EVENTS specifics ---------------------- */
//
/* ---------------------- SCENARIO specifics ---------------------- */
//

/* -------------------------- PARAMETRES specifics -------------------------- */

app-functional-article{
    .mat-mdc-tab{
        min-width: 160px!important;
    }
}

app-scale-amount{
    .mat-mdc-tab:not(:has(.addNewEvolutionGroupButton)){
        min-width: 160px!important;
    }
    .mat-mdc-tab:has(.addNewEvolutionGroupButton){
        padding: 0px!important;
        min-width: 0px!important;
        width: auto;
    }
    .mat-mdc-tab-disabled:has(.addNewEvolutionGroupButton){
        opacity: 1;
    }
}

app-server-paginator{
    .mdc-notched-outline__trailing,
    .mdc-notched-outline__notch,
    .mdc-notched-outline__leading {
        border: none !important;
    }
}

/* ---------------------------- PROCESS specifics --------------------------- */
//

  /* ---------------------------- BIZZDEV specifics --------------------------- */

app-metadata{
    .mat-mdc-paginator-range-label {
        margin: 0 20px 0 10px!important;
    }
}

app-requests-filter, app-logs-filter, app-hangfire-filter{
    .time-stamp span{
             font-size: 14px;
    }
}

/* ----------------------------- WIKI specifics ----------------------------- */

app-wiki {
    .mat-mdc-form-field-flex{
        height: 46px!important;
    }
    mat-panel-title form .mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined) .mat-mdc-form-field-infix{
        padding-bottom: 0!important;
        padding-top: 16px!important;
    }
    .mat-mdc-checkbox .mdc-form-field>label{
        color: $primary-light!important;
    }
    mat-nav-list mat-expansion-panel .mat-mdc-form-field 
    .mdc-text-field--filled:not(.mdc-text-field--disabled) .mdc-line-ripple::before,
    mat-nav-list mat-expansion-panel .mat-mdc-form-field 
    .mdc-text-field--filled:not(.mdc-text-field--disabled) .mdc-line-ripple::after{
        border-bottom-color: white!important;
    }
    .editor-title-div{
        .mat-mdc-form-field, .mat-form-field-disabled {
            --mdc-typography-body1-font-size: 14px!important;
            --mdc-filled-text-field-label-text-size: 14px!important;
            --mat-select-trigger-text-size: 14px!important;
            .mat-mdc-form-field-input-control.mat-mdc-form-field-input-control{
                font-size: 16px!important;
                color: $primary-dark;
                font-weight: 400;
            }
        }
    }
}

app-editor-container{
    .mat-mdc-tab-list.mat-mdc-tab{
            min-width: 160px
        }
}

app-editor{
    .editor{
        p,
        ul,
        li {
            line-height: 1.5!important;
        }
    }
}

app-side-nav-tree mat-tree-node{
    a{
        color: $primary-light;
        text-decoration: none;
    }
    button .blue-chevron{
        color: $accent;
    }
}
  



/* --------------------------- Form filters -------------------------- */
app-id-filter {
    .mat-form-field-label-wrapper {
        top: -0.25em!important;
    }
    input.mat-input-element {
        margin-top: 8px;
        margin-bottom: -2px;
    }
    .mat-form-field-empty.mat-form-field-label:not(.mat-form-field.mat-focused .mat-form-field-label.mat-accent){
        margin-top: 2px!important;
    }
}

