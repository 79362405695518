@import 'variables';

#fm-pivot-view .fm-grid-view div.value {
    font-weight: normal;
    color: $primary-dark;
}
#fm-pivot-view .fm-grid-view div.total {
    color: $primary-dark
}

app-pivot-table {
    #fm-toolbar-wrapper {
        height: 0;
    }
}

// fm-pivot > div {
//     height: calc(100% - 50px);
// }

// .fm-branding-bar {
//     display: none !important;
// }

#fm-pivot-view button.fm-ui-btn,
#fm-toolbar-wrapper button.fm-ui-btn,
#fm-pivot-view a.fm-ui-btn,
#fm-toolbar-wrapper a.fm-ui-btn {
    color: $contrast-color;
    border: 1px solid $accent;
    background-color: $accent;
}

#fm-pivot-view button.fm-ui-btn:hover,
#fm-toolbar-wrapper button.fm-ui-btn:hover,
#fm-pivot-view a.fm-ui-btn:hover,
#fm-toolbar-wrapper a.fm-ui-btn:hover {
    color: $contrast-color;
    border-color: $accent-dark;
    background-color: $accent-dark;
}

#fm-pivot-view a.fm-ui-btn-dark,
#fm-toolbar-wrapper a.fm-ui-btn-dark,
#fm-pivot-view button.fm-ui-btn-dark,
#fm-toolbar-wrapper button.fm-ui-btn-dark {
  color: $contrast-color;
  background: $accent;
}

#fm-pivot-view a.fm-ui-btn-dark:hover,
#fm-toolbar-wrapper a.fm-ui-btn-dark:hover,
#fm-pivot-view button.fm-ui-btn-dark:hover,
#fm-toolbar-wrapper button.fm-ui-btn-dark:hover {
    color: $contrast-color;
    background: $accent-dark;
}

#fm-pivot-view a.fm-ui-btn-light,
#fm-toolbar-wrapper a.fm-ui-btn-light,
#fm-pivot-view button.fm-ui-btn-light,
#fm-toolbar-wrapper button.fm-ui-btn-light {
    background: $accent;
    color: $contrast-color;
}

#fm-pivot-view a.fm-ui-btn-light:hover,
#fm-toolbar-wrapper a.fm-ui-btn-light:hover,
#fm-pivot-view button.fm-ui-btn-light:hover,
#fm-toolbar-wrapper button.fm-ui-btn-light:hover {
    background: $accent-dark;
    color: $contrast-color;
}

#fm-pivot-view a.fm-ui-btn.fm-ui-btn-close,
#fm-toolbar-wrapper a.fm-ui-btn.fm-ui-btn-close,
#fm-pivot-view button.fm-ui-btn.fm-ui-btn-close,
#fm-toolbar-wrapper button.fm-ui-btn.fm-ui-btn-close {
    color: $accent;
}

#fm-pivot-view .fm-ui-context-menu {
    background-color: $contrast-color;
    border: 1px solid $accent;
}

#fm-pivot-view .fm-ui-context-menu .fm-ui-context-submenu {
    background-color: $contrast-color;
    border: 1px solid $accent;
}
// grid's title
#fm-pivot-view span.fm-ui-label.fm-pivot-title {
    color: $primary;
    border-bottom: 1px solid #f0f0f0;
    font-size: 20px;
    background-color: $contrast-color;
}

#fm-pivot-view .fm-charts-view .fm-chart-legend ul li.fm-expanded .fm-icon-display:before {
    background: $accent;
}

// hover on open fields arrow button
#fm-pivot-view .fm-fields-view-wrap button.fm-btn-open-fields:hover {
    background-color: $primary-light;;
}

#fm-pivot-view .fm-fields-view-wrap button.fm-btn-open-fields:before {
    color: $accent;
}

#fm-pivot-view .fm-fields-view .fm-btn-add-measure-2:before {
    color: $contrast-color;
}

#fm-pivot-view .fm-fields-view .fm-btn-add-measure-2:hover:before {
    color: $contrast-color;
    background-color: $accent-dark;
}

// add calculated values icon in fields list
#fm-pivot-view .fm-fields-view .fm-popup-header .fm-btn-add-measure:before {
    color: $contrast-color;
}

#fm-pivot-view .fm-fields-view .fm-popup-header .fm-btn-add-measure:hover:before {
    // color: #039BE5;
    color: $contrast-color;
}

// header in fields list
#fm-pivot-view .fm-fields-view .fm-popup-content .fm-list-wrap .fm-list-header {
    background: $accent;
}

// text in headers
#fm-pivot-view .fm-fields-view .fm-popup-content .fm-list-wrap .fm-list-header span.fm-ui-label {
    color: $contrast-color;
}

#fm-pivot-view .fm-fields-view .fm-popup-content .fm-list-wrap .fm-list-content li .fm-drag-handle {
    color: $primary;
}

#fm-pivot-view .fm-fields-view .fm-popup-content .fm-list-wrap .fm-list-content li.fm-values:before {
    color: $accent;
}

#fm-pivot-view .fm-fields-view .fm-popup-content .fm-list-wrap .fm-list-content .fm-lst-hierarchies li.fm-checked:not(.fm-level-folder) .fm-icon-display {
    background: $accent;
    border: 1px solid $accent;
}

#fm-pivot-view .fm-fields-view .fm-popup-content .fm-list-wrap .fm-list-content .fm-lst-hierarchies li.fm-measure:not(.fm-calculated):after {
    color: $accent;
}

// aggregation symbol for values in list fields
#fm-pivot-view .fm-fields-view .fm-popup-content .fm-list-wrap .fm-list-content .fm-lst-measures .fm-aggr-display:before,
#fm-pivot-view .fm-fields-view .fm-popup-content .fm-list-wrap .fm-list-content .fm-lst-measures .fm-aggr-display:after {
    color: $accent;
}

#fm-pivot-view .fm-fields-view.fm-flat-fields .fm-popup-content .fm-list-wrap .fm-list-content .fm-lst-hierarchies li.fm-calculated .fm-calc-display {
    color: $accent;
}

#fm-pivot-view .fm-fields-view.fm-flat-fields .fm-popup-content .fm-list-wrap .fm-list-content .fm-lst-hierarchies li.fm-calculated .fm-calc-display:hover {
    color: $accent;
}

#fm-pivot-view .fm-grid-layout .fm-filters.fm-page-filter a.fm-filter-header {
    background-color: $contrast-color;
}

#fm-pivot-view .fm-grid-layout .fm-filters.fm-page-filter a.fm-filter-header:hover {
    background-color: $primary-light;
}

#fm-pivot-view .fm-grid-layout .fm-filters.fm-page-filter a.fm-filter-header .fm-filter-desc {
    // display: block;
    color: $accent;
}

#fm-pivot-view .fm-grid-layout .fm-filters .fm-filter-header,
#fm-pivot-view .fm-grid-layout.fm-flat-view .fm-filter-header {
    background-color: $contrast-color;
    color: $accent;
}

// gear icon next to columns and rows fields
#fm-pivot-view .fm-grid-layout .fm-filter-header i.fm-filter-icon:before {
    color: $accent;
}

// filter icon next to columns and rows fields
#fm-pivot-view .fm-grid-layout .fm-filter-header.fm-filtered i.fm-filtered-icon:before {
    color: $accent-light;
}


#fm-pivot-view .fm-grid-layout .fm-filters.fm-cols-filter .fm-filter-header {
    border-right: none;
    border-bottom: 1px solid #f0f0f0;
    background-color: $contrast-color;
    color: $accent;
}

#fm-pivot-view .fm-grid-layout .fm-filters.fm-cols-filter .fm-filter-header:hover {
    background-color: $primary-light;
}

#fm-pivot-view .fm-grid-layout .fm-filters {
    background-color: $contrast-color;
    color: $accent;
    border-right: 1px solid #f0f0f0;
    border-bottom: 1px solid #f0f0f0;
}

#fm-pivot-view .fm-grid-layout .fm-cols-filter .fm-row {
    border-bottom: 1px solid #f0f0f0;
}

#fm-pivot-view .fm-grid-layout .fm-filters .fm-header,
#fm-pivot-view .fm-grid-layout.fm-flat-view .fm-header {
    background-color: $contrast-color;
    color: $accent;
    border-bottom: 1px solid #f0f0f0;
    border-right: 1px solid #f0f0f0;
}

#fm-pivot-view .fm-grid-layout .fm-filters.fm-rows-filter .fm-filter-header {
    border-right: 1px solid #f0f0f0;
    border-bottom: 1px solid #f0f0f0;
    background-color: $contrast-color;
    color: $accent;
}

#fm-pivot-view .fm-grid-layout .fm-filters.fm-rows-filter .fm-filter-header:hover {
    background-color: $primary-light;
}

// icons in rows and columns label cell
#fm-pivot-view .fm-grid-layout i.fm-icon:before {
    color: $accent;
}

// vertical line appearing while resizing rows and columns
#fm-pivot-view .fm-grid-layout .fm-resize-handles .fm-indicator {
    border: 1px solid $accent;
}

// handle to resize rows and columns
#fm-pivot-view .fm-grid-layout .fm-resize-handles .fm-handle:hover {
    border: 4px solid $accent;
}

// handle to resize rows and columns when clicked
#fm-pivot-view .fm-grid-layout .fm-resize-handles .fm-handle.fm-active {
    border: 4px solid $accent;
}

// checkbox in rows and columns filter section (click on the gear icon)
#fm-pivot-view a.fm-ui-checkbox.fm-selected .fm-ui-label:before {
    border: 1px solid $accent;
    background: $accent;
}

// loop icon in rows and columns filter section (click on the gear icon)
#fm-pivot-view .fm-filter-view .fm-filters-table .fm-filters-table-header .fm-search-wrap .fm-search-btn:after {
    color: $accent;
}

// radio button in options menu (gear icon in the toolbar)
#fm-toolbar-wrapper .fm-toolbar-ui .fm-radio-wrap label:hover:before {
    border-color: $accent;
}
#fm-toolbar-wrapper .fm-toolbar-ui .fm-radio-wrap label:after {
    border: 7px solid $accent;
    left: 4px;
}

// Conditional formatting
#fm-toolbar-wrapper .fm-popup-conditional {
    width: 680px;
    min-width: 400px;
}

// "add" button in conditional formating
#fm-toolbar-wrapper .fm-toolbar-ui .fm-button-add .fm-icon {
    color: $contrast-color;

    &:hover {
        color: $contrast-color;
    }
}
#fm-toolbar-wrapper .fm-toolbar-ui .fm-button-add-large {
    .fm-icon {
        color: $contrast-color;
    }

    &:hover {
        .fm-icon {
            color: $contrast-color;
        }
    }
}

// checkmarks in lists with multiple selection
#fm-pivot-view .fm-ui-context-menu .fm-ui-list li.fm-selected:before,
#fm-pivot-view .fm-aggregations-view ul.fm-ui-list li.fm-selected:before {
    color: $accent;
}

// loop icon besides "All fields" in fields view
#fm-pivot-view .fm-fields-view .fm-popup-content .fm-list-wrap .fm-list-header .fm-search-wrap .fm-search-btn .fm-icon-act_search {
    color: $contrast-color;
}

// little arrows to expand folders in fields view
#fm-pivot-view .fm-fields-view .fm-popup-content .fm-list-wrap .fm-list-content .fm-lst-hierarchies li.fm-folder .fm-icon-display:before {
    color: $accent;
}


#fm-pivot-view .fm-grid-layout .fm-sheet-selection-canvas {
    background-color: $flexmonster-selection;
}
